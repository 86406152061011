import React, { useState , useEffect} from "react";
import {useNavigate} from "react-router-dom"
import BannerSlider from "../components/BannerSlider";
import config from "../config";
import Spinner from "../components/Spinner/Spinner";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Counter_section from "../components/Static-Section/Counter-section";
// import FeedBack from "../components/FeedBack";
import Faq from "../components/Faq";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BasicGrid from "../components/Static-Section/BasicGrid";
import customer_layer_1 from '../asset/images/modular-page-assets/customer-layer-2.png'
import customer_layer_2 from '../asset/images/modular-page-assets/customer-layer-1.png'
import Value_section from "../components/Static-Section/Values_Section";
import Aruwa_edge from "../components/Static-Section/Aruwa_Edge_Table";
import Calculate_now from "../components/Static-Section/Calculate_section";
import TransformSpace from "../components/Static-Section/TransformSpace";
import CustomerSection from "../components/Static-Section/CustomerSection";
import test_back from '../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../asset/images/modular-page-assets/test-item-2.png'
import Faq2 from "../components/Faq2";
import CityContact from "../components/CityContact";


const Home = () => {
  const categorySlug = "faq-home";
  const [activeData, setActiveData] = useState("Data 1");
  const [activeDataSubTab, setActiveDataSubtab] = useState("SubData 1");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [blogsData, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${config.base_url}home`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setBlogs(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const handleReadMore = (data) => {
    // {`/blog-detail/${data.slug}
    navigate("/blog/" + data.slug, { state: { blogData: data } });
  };

  const handleButtonClick = (data) => {
    setActiveData(data);
  };

  const handleButtonClickSubTab = (data) => {
    setActiveDataSubtab(data);
  };

  const options = {
    items: 1, // Number of items to show
    loop: true, // Enable looping
    autoplay: true, // Enable autoplay

    autoplaySpeed: 500, // Autoplay speed in milliseconds
  };

  const aruwa_selective_options = {
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };


  const slides = [
    {
      'id': 1,
      'title': 'We Create The Perfect Blend of Style and Functionality',
      'sub_title': "",
      'background':  process.env.PUBLIC_URL+'/asset/images/banner/home/home-slide-3.png'
    },
    {
      'id': 2,
      'title': '',
      'h2_title': 'We Bring Your Modular Kitchen Dreams to Life',
      'sub_title': "",
      'background':  process.env.PUBLIC_URL+'/asset/images/banner/home/home-slide-2.png'
    },
    {
      'id': 2,
      'title': '',
      'h2_title': 'We Deliver Modern Interior Solutions to Create a Perfect Space',
      'sub_title': "",
      'background':  process.env.PUBLIC_URL+'/asset/images/banner/home/home-slide-1.png'
    },
  ];

  const blogs_data = [
    {
      'id': 1,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/blog/blog-1.png',
      'grid_title': '9 Living Room Decorating Ideas'
    },
    {
      'id': 2,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/blog/blog-2.png',
      'grid_title': 'What makes difference when hiring an Interior Designer?'
    },
    {
      'id': 3,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/blog/blog-3.png',
      'grid_title': ' 9 Modern tips for maximising space in your kitchen'
    },
  ];

  const BasicGrid_content = [
    {
      'id': 1,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/living-room.png',
      'grid_title': ' Living Room'
    },
    {
      'id': 2,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/kitchen.png',
      'grid_title': 'Kitchen'
    },
    {
      'id': 3,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/master-bedroom.png',
      'grid_title': ' Master Bedroom'
    },
    {
      'id': 4,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/wardrobes.png',
      'grid_title': ' Wardrobes'
    },
    {
      'id': 5,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/kid-bedroom.png',
      'grid_title': "Kid's Bredroom"
    },
    {
      'id': 6,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/pooja-room.png',
      'grid_title': ' Pooja Room'
    },
    {
      'id': 7,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/dining-room.png',
      'grid_title': ' Dining Room'
    },
    {
      'id': 8,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/foyer.png',
      'grid_title': 'Foyer'
    },
    {
      'id': 9,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/bathroom.png',
      'grid_title': 'Bathroom'
    },
    {
      'id': 10,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/guest-bedroom.png',
      'grid_title': 'Guest Bedroom'
    },
    {
      'id': 11,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/home-office.png',
      'grid_title': 'Home Office'
    },
    {
      'id': 12,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/balcony.png',
      'grid_title': 'Balcony'
    },
    {
      'id': 13,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/workspace.png',
      'grid_title': 'Workspace'
    },
    {
      'id': 14,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/retail-store.png',
      'grid_title': 'Retail Store'
    },
    {
      'id': 15,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/institutions.png',
      'grid_title': 'Institutions'
    },
    {
      'id': 16,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/home/basic-grid/hospitality.png',
      'grid_title': 'Hospitality'
    },
  ];


  return (
    <>

      <Helmet>
        <title>
          {" "}
          Aruwa Interiors: Best Interior Designer Compnay in India 
        </title>
        <meta
          name="description"
          content="Aruwa is one of the top interior designer companies in India, offering complete turnkey services for residential and commercial spaces. 
Explore our portfolio of stunning designs and book a free consultation with Aruwa today."
        />
      </Helmet>
      {/* banner */}
      <section>
        <div className="container-fluid">
          <div className="row">
            <BannerSlider slides={slides} btnClass={'home'} carouselClass={'home-carousel'}></BannerSlider>
          </div>
        </div>
      </section>

      <section className="banner-content-mb d-none">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>We Create The Perfect Blend of Style and Functionality</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

      <Counter_section></Counter_section>


      {/* we offers ideas */}
      <section className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>We Deliver Interior Solutions with Remarkable Details</h2>
              <h5>
                We have grouped our interior services into three categories to clarify our service offerings
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* aurwa elite  */}
      <section>
        <div className="container overflow-container ">
          <div className="row justify-content-center">
            <OwlCarousel
              responsiveClass
              responsive={aruwa_selective_options.responsive}
              className=" owl-theme offer-ideas-slider"
            >
              <div className=" item">
                <div className="product">
                  <img
                    src="/../../asset/images/home/selective-image.png"
                    alt="Avatar"
                    className="image w-100"
                  />
                  <div className="overlay">
                    <Link to='/modular-kitchen-jaipur'>
                      <h4 className="text-dark">
                        Aruwa <br />
                        Selective
                      </h4>
                    </Link>
                    <p className="text-justify">
                      Customize your home interiors with our personalized interior solutions tailored to fit your needs. Be it a modular kitchen, modular wardrobes, or a wide range of customisable modular furniture options, we have got you covered.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="product">
                  <img
                    src="/../../asset/images/home/aruwa-elite.png"
                    alt="Avatar"
                    className="image w-100"
                  />
                  <div className="overlay">
                    <Link to='/full-home-interiors'>
                      <h4 className="text-dark">
                        Aruwa <br />
                        Elite 
                      </h4>
                    </Link>
                    <p className="text-justify">
                      Aruwa Elite, where we offer you a complete home interior solution that is incomparable. From design to implementation, we promise a hassle-free experience to transform.{" "}
                    </p>

                  </div>
                </div>
              </div>
              <div className=" item">
                <div className="product">
                  <img
                    src="/../../asset/images/home/aruwa-commercial.png"
                    alt="Avatar"
                    className="image w-100"
                  />
                  <div className="overlay">
                    <Link to="/commercial-interior">
                      <h4 className="text-dark">
                        Aruwa <br />
                        Premium
                      </h4>
                    </Link>
                    <p className="text-justify">
                      Aruwa Premium delivers all-inclusive interior solutions for your professional environments, whether they are corporate offices, cozy cafes or fine dining restaurants.{" "}
                    </p>

                  </div>
                </div>
              </div>
            </OwlCarousel>
            <Link
              to={"/contact-us"}
              className="btn hire-us my-3"
            >
              BOOK FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <div className="space d-none d-md-block"></div>

      <section className="d-none estimate py-4 gray-background back-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/asset/images/home/calc-back.jpg'})` }}>

        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-md-5 text-center">
              <h2>
                Get the Estimate for your <span className="yellow-text no-black">Dream Space</span>
              </h2>
              <h5>Calculate the approximate cost of designing your interiors 
              <img className="calc-icon" src={process.env.PUBLIC_URL + '/asset/images/home/calc_icon.png'} />
              </h5>
            </div>
            <div className="col-12 d-md-none">

              <OwlCarousel
                responsiveClass
                responsive={aruwa_selective_options.responsive}
                className=" owl-theme estimate-section"
              >
                <div className=" item">
                  <div className="estimate-item">
                    <img src='../asset/images/home/wardrobe.svg' className="img-fluid estimate-icon" />
                    <h3>Wardrobe</h3>
                    <h5>Our rough math for your wardrabe</h5>
                    <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                      CALCULATE
                    </Link>
                  </div>
                </div>
                <div className="item">
                  <div className="estimate-item">
                    <img src='../asset/images/home/kitchen.svg' className="img-fluid estimate-icon" />
                    <h3>Kitchen</h3>
                    <h5>Get an approximate costing for your kitchen interior</h5>
                    <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                      CALCULATE
                    </Link>
                  </div>
                </div>
                <div className=" item">
                  <div className="estimate-item">
                    <img src='../asset/images/home/home-interior.svg' className="img-fluid estimate-icon" />
                    <h3>Full Home Interior</h3>
                    <h5>Know the estimate price for your full home interiors</h5>
                    <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                      CALCULATE
                    </Link>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            <div className="col-md-4 mb-5 py-4 mb-lg-5 d-md-block d-none">
              <div className="estimate-item">
                <img src={ process.env.PUBLIC_URL+'/asset/images/home/home-interior.svg'}  className="img-fluid estimate-icon" />
                <h3>Full Home Interior</h3>
                <h5>Know the estimate price for your full home interiors</h5>
                <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                  CALCULATE
                </Link>
              </div>
            </div>
            <div className="col-md-4 mb-5 py-4 mb-lg-5 d-md-block d-none">
              <div className="estimate-item">
                <img src={ process.env.PUBLIC_URL+'/asset/images/home/kitchen.svg' } className="img-fluid estimate-icon" />
                <h3>Kitchen</h3>
                <h5>Get an approximate costing for your kitchen interior</h5>
                <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                  CALCULATE
                </Link>
              </div>
            </div>
            <div className="col-md-4 mb-5 py-4 mb-lg-5 d-md-block d-none">
              <div className="estimate-item">
                <img src={ process.env.PUBLIC_URL+'/asset/images/home/wardrobe.svg' } className="img-fluid estimate-icon" />
                <h3>Wardrobe</h3>
                <h5>Our rough math for your wardrabe</h5>
                <Link to={"/contact-us"} className="btn hire-us radius-50 my-3 estimate-btn">
                  CALCULATE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-md-5 py-3 text-dark bg-white back-layer-container">
        <div className='back-layer-1 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_1} />
        </div>
        <div className='back-layer-2 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_2} />
        </div>
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-12 mb-4 text-black text-center">
              <h2 className="fw-bold mb-md-4">
                End-To- End <span className="yellow-text">Interior Solutions</span>
              </h2>
              <p>We have a diverse range of interior design services to match <br className="d-none d-md-block" /> your home’s needs. Check out the sections below to find out more</p>
            </div>
            <BasicGrid grid_items={BasicGrid_content} column={'3'} small={6}></BasicGrid>
          </div>
        </div>
      </section>

      <Value_section />
      <Aruwa_edge />
      <Calculate_now btn_text={'Book Free Consultation'} page_title={'Ready To Design Your Dream Home?'} />
      <TransformSpace />
      <CustomerSection />
     
      <section className="p-3 gray-background">
        <div className="container-fluid dark-bordered p-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="fw-bold magnify-heading">
                <span className="magnify-text">
                  <img src={process.env.PUBLIC_URL + '/asset/images/magnify.png'} />
                  B
                </span>logs
              </h2>
            </div>
            <div className="col-md-12">
            <div className="row align-items-stretch justify-content-center text-black blogs-section">
            {error ? (
        <div>Error: {error.message}</div>
      ) : !isLoaded ? (
        <div>
          <Spinner />
        </div>
      ) : (

    blogsData.blogs.map((data,index) => (
                            <div className={"col-md-4 p-2 cursor-pointer"} key={index}>
                                <Link className=" p-3 h-100" to={`/blog/${data.slug}`}>
                              
                                    <div className="row flex-column basic-grid w-100">
                                        <div className="col-md-12 col-12 p-0 text-center mb-3">
                                            <img
                                                src={data.featured_image_url}
                                                className={"w-100 "+(index == 2 ? "square-aspect-lg" : "")}
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-12 col-12 p-0 text-center ">
                                            <h5 className="text-dark">{data.title}</h5>
                                        </div>
                                    </div>
                                 
                                </Link>
                            </div>
      ))
    )}
</div>
<div className="col-md-12 text-center">
                                <Link
                                    to={"/blogs"}
                                    className="btn hire-us radius-50 my-3"
                                >
                                     READ MORE
                                </Link>
                            </div>
          </div>
        </div>
        </div>
      </section>
      <section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
        <div className="test-layer-1">
          <img src={test_item_1} className="img-fluid" />
        </div>
        <div className="test-layer-2">
          <img src={test_item_2} className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
              <Faq2 categorySlug={"faq-home"} ></Faq2>
            </div>
          </div>
        </div>
      </section>
      <CityContact />

    </>
  );
};

export default Home;
