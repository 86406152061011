import {  useLocation, useParams , Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState ,useEffect} from 'react';
import config from "../../config";
import Spinner from "../../components/Spinner/Spinner";
import Moment from 'moment';
import { Helmet } from "react-helmet";


const Blog =()=>{

        const navigate = useNavigate();
        const { state } = useLocation();
        const { id } = useParams();
        const uploadUrl = `${config.base_uploadUrl}post_type_pages/`;
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [blogData, setBlog] = useState([]);
        const [next, setNext] = useState('');

        const ReadMore = (data) => {
               navigate("/blog/" + data.slug, { state: { blogData: data } });
          };

        useEffect(() => {
            let slug = id;
            if(next !== ''){
              slug = next;
            }
          
            fetch(`${config.base_url}blog/${slug}`) 
              .then((res) => res.json())
              .then(
                (result) => {
                  setIsLoaded(true);
                  setBlog(result);
                },
                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
              );
          }, [id]);


          if (error) {
            return <div>Error: {error.message}</div>;
          } else if (!isLoaded) {
            return (
              <div>
                <Spinner/>
              </div>
            );
          } else {

    return (
        <>
        <Helmet>
            <title>
             {blogData.blog.meta_title}
            </title>
            <meta
            name="description"
            content={blogData.blog.meta_desc}
            />

        <link rel="canonical" href={`https://aruwa.in/blog/${blogData?.blog?.slug}`} />

        </Helmet>
        <section className=" pt-5  pb-5 ">
        <div className="col-md-12">
                    <img src={`${uploadUrl}${blogData.blog.featured_image}`}  className="w-100" alt={`${blogData.blog.alt_text}`}/>
                </div>
            <div className="container pt-5">
            <div className="row">
                {/* <div className="col-12 mb-2 text-center text-md-right">
                    <a  className="previous-btn btn btn-outline-dark rounded-0 px-md-4 py-md-3 m-2">Previous</a>
                    <a  className="next-btn btn btn-outline-dark rounded-0 px-md-4 py-md-3 m-2">Next</a>
                </div> */}
                
                <div className="col-md-12 my-3">
                    <h1 className="fw-bold display-6">{blogData.blog.title}</h1>
                </div>
                <div className="col-md-12 mb-3 flex">
                    <div className="flex align-items-center">
                        <div className="mb-3 d-md-inline">
                            <a href="" className="btn btn-outline-dark radius-50 px-md-3 me-2">Office Interior</a>
                            <a href=""  className="btn btn-outline-dark radius-50 px-md-3">Research</a>
                        </div>
                        <div className="mb-3 d-md-inline">
                            <span className="mx-3">{Moment(blogData.blog.created_at).format('D MMM YYYY')}</span>
                            <span className="mx-3">{blogData.blog.author.name}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" dangerouslySetInnerHTML={{ __html: blogData.blog.content }}>
                        
                </div>
                {/* <div className="col-md-12 text-center">
                <a  className="previous-btn btn btn-outline-dark rounded-0 px-md-4 py-md-3 m-2">Previous</a>
                    <a  className="next-btn btn btn-outline-dark rounded-0 px-md-4 py-md-3 m-2">Next</a>
                </div> */}
            </div>
            </div>
        </section>

        <section className="py-md-5 py-3">
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-8">
                        <h3 className="display-6 fw-bold mb-md-5">Recent Stories </h3>
                    </div>
                </div>
                <div className="row align-items-stretch">
                {blogData.recent_stories.map((data) => {
                                return( 
                <div className="col-md-4 p-3 p-md-2 mb-md-5" key={data.id}>
                    <Link className="btn" to={`/blog/${data.slug}`}>
                        <div className=" h-100">
                            <div className="flex flex-column">
                                <div className="col-md-12 col-12 p-0 text-center mb-3">
                                    <img src={`${uploadUrl}${data.featured_image}`} className="w-100"
                                        alt={`${data.alt_text}`}/>
                                </div>
                                <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                    <h5>{data.title}</h5>
                                    <p className="mb-2">{data.short_desc}</p>
                                    <span className="me-4">{Moment(data.created_at).format('D MMM YYYY')}</span><span>{data.author.name}</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    </div>
                        );
                    })}
                </div>
                
            </div>
        </section>

        <section className="container-fluid bg-yellow py-5">
            <section className="container">
                <div className="row">
                    <div className="col-md-6 text-left">
                        <div className=" h-100">
                            <h4 className="display-6 fw-bold mb-md-4">Signup to our newsletter</h4>
                            <h5 className="fw-lighter">Stay up to date with the latest news and articles.</h5>
                        </div>
                    </div>
                    <div className="col-md-6 px-md-1 py-md-3 p-4">

                        <form className="newsletter-form">
                            <div className="row">
                                <div className="col-7">
                                    <input type="email" className="form-control h-100 border-dark bg-yellow "
                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email"/>
                                </div>
                                <div className="col-5">
                                    <button type="submit"
                                        className="btn btn-dark text-capitalize px-md-5 py-md-3 ">Subscribe</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        </section>
        </>
    )}
}
export default Blog;